@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap);
* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  line-height: 1;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-size: 100%;
  font-family: inherit;
  line-height: 1;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  overflow: auto;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

fieldset {
  border: none;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-before: 0;
  -webkit-padding-start: 0;
  -webkit-padding-end: 0;
  -webkit-padding-after: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
}

legend {
  padding-inline-start: 0;
  padding-inline-end: 0;
}

input[type='text'] {
  -webkit-appearance: none;
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

* {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Calibri';
  font-style: normal;
  src: url(/static/media/Calibri.f9088acf.woff2) format("woff2"),url(/static/media/Calibri.482680ca.woff) format("woff");
}

p {
  font-family: 'Calibri', sans-serif;
  color: rgba(0,0,0,0.8);
  font-size: 2.2rem;
  line-height: 1.4;
}

@media screen and (max-width: 900px) {
  p {
    font-size: 2rem;
  }
}

body {
  font-family: 'Calibri', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.App-module__app___2vSOp {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column wrap;
  align-items: center;
  height: 100%;
}


